// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gj3z7:hover{cursor:pointer}.gj3z7::part(step-bg){width:40px}.f_TMn::part(nav-sidebar){position:relative;width:300}`, "",{"version":3,"sources":["webpack://./src/components/SidebarCmp.module.scss"],"names":[],"mappings":"AACE,aACE,cAAA,CAEF,sBACE,UAAA,CAKF,0BACE,iBAAA,CACA,SAAA","sourcesContent":[".step {\n  &:hover {\n    cursor: pointer;\n  }\n  &::part(step-bg) {\n    width: 40px;\n  }\n}\n\n.sidebar {\n  &::part(nav-sidebar) {\n    position: relative;\n    width: 300;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"step": `gj3z7`,
	"sidebar": `f_TMn`
};
export default ___CSS_LOADER_EXPORT___;
