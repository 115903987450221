import { WppToastContainer } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import styles from 'app/App.module.scss'
import RoutesCmp from 'app/Routes'
import { SidebarCmp } from 'components/SidebarCmp'
import { ProjectContext } from 'contexts/Project'

export function App() {
  const [projectId, setProjectId] = useState<string | null>(null)
  const { osContext } = useOs()

  const setProjectIdHandler = (projectIdParam: string | null) => {
    if (projectId !== projectIdParam) {
      setProjectId(projectIdParam)
    }
  }

  return (
    <BrowserRouter basename={osContext.baseUrl}>
      <div className={styles.container}>
        <div className={styles.pageContent}>
          <ProjectContext.Provider value={{ projectId, setProjectId: setProjectIdHandler }}>
            <div className="flex flex-row w-full items-stretch justify-start">
              <SidebarCmp />
              {/* Content */}
              <div className="flex-1 px-3 w-full">
                <RoutesCmp />
                {/* <Route path="/navigation-workspaces" element={<NavigationAndWorkspacesPage />} /> */}
              </div>
            </div>
          </ProjectContext.Provider>
        </div>
        <WppToastContainer />
      </div>
    </BrowserRouter>
  )
}
