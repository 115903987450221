import React, { useEffect } from 'react'
import { Navigate, Route, Routes, useMatch } from 'react-router-dom'

import useProjectContext from 'hooks/useProjectContext'

const HomePage = React.lazy(() => import('pages/home/HomePage'))
const NewProjectPage = React.lazy(() => import('pages/new-project/NewProjectPage'))
const RfiSummaryPage = React.lazy(() => import('pages/rfi-summary/RfiSummaryPage'))
const RelevantNotesPage = React.lazy(() => import('pages/relevant-notes/RelevantNotesPage'))
const ProposedResponseStructurePage = React.lazy(
  () => import('pages/proposed-response-structure/ProposedResponseStructurePage'),
)
const AnswerQuestionsPage = React.lazy(() => import('pages/answer-questions/AnswerQuestionsPage'))
const AiDraftResponse = React.lazy(() => import('pages/ai-draft-response/AiDraftResponse'))

export default function RoutesCmp() {
  const { setProjectId } = useProjectContext()
  const matchNewProject = useMatch('/rfi-helper-tool/new-project')
  const matchRfiSummary = useMatch('/rfi-helper-tool/rfi-summary/:projectId')
  const matchRelevantNotes = useMatch('/rfi-helper-tool/relevant-notes/:projectId')
  const matchProposedResponse = useMatch('/rfi-helper-tool/proposed-response-structure/:projectId')
  const matchAnswerQuestions = useMatch('/rfi-helper-tool/answer-questions/:projectId')
  const matchDraftResponse = useMatch('/rfi-helper-tool/ai-draft-response/:projectId')

  const activeMatch =
    matchNewProject ||
    matchRfiSummary ||
    matchRelevantNotes ||
    matchProposedResponse ||
    matchAnswerQuestions ||
    matchDraftResponse

  useEffect(() => {
    if (activeMatch && activeMatch.params.projectId) {
      setProjectId(activeMatch.params.projectId)
    } else {
      setProjectId(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMatch])

  return (
    <Routes>
      <Route index element={<Navigate replace to="rfi-helper-tool/home" />} />
      <Route path="rfi-helper-tool/home" element={<HomePage />} />
      <Route path="rfi-helper-tool/new-project" element={<NewProjectPage />} />
      <Route path="rfi-helper-tool/rfi-summary/:projectId" element={<RfiSummaryPage />} />
      <Route path="rfi-helper-tool/relevant-notes/:projectId" element={<RelevantNotesPage />} />
      <Route
        path="rfi-helper-tool/proposed-response-structure/:projectId"
        element={<ProposedResponseStructurePage />}
      />
      <Route path="rfi-helper-tool/answer-questions/:projectId" element={<AnswerQuestionsPage />} />
      <Route path="rfi-helper-tool/ai-draft-response/:projectId" element={<AiDraftResponse />} />
      <Route path="*" element={<Navigate replace to="/rfi-helper-tool/home" />} />
    </Routes>
  )
}
