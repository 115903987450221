import {
  WppButton,
  WppIconChatMessage,
  WppIconDocument,
  WppIconHome,
  WppIconPitch,
  WppIconTaskList,
  WppIconUpload,
  WppNavSidebar,
  WppNavSidebarItem,
} from '@wppopen/components-library-react'
import { useNavigate } from 'react-router-dom'

import useProjectContext from 'hooks/useProjectContext'

import styles from './SidebarCmp.module.scss'

export const navigation = {
  home: '/rfi-helper-tool/home',
  newProject: '/rfi-helper-tool/new-project',
  rfiSummary: '/rfi-helper-tool/rfi-summary',
  relevantNotes: '/rfi-helper-tool/relevant-notes',
  proposedResponseStructure: '/rfi-helper-tool/proposed-response-structure',
  answerQuestions: '/rfi-helper-tool/answer-questions',
  draftResponse: '/rfi-helper-tool/ai-draft-response',
}

export function SidebarCmp() {
  const navigate = useNavigate()
  const { projectId } = useProjectContext()

  const handleChangeRoute = (event: CustomEvent) => {
    console.log('event', event.detail.path, projectId)
    // if (!!projectId) {
    //   navigate(`${event.detail.path}/${projectId}`)
    // }
    navigate(event.detail.path)
    // if (!projectId) {
    //   if (event.detail.path.includes(navigation.newProject)) {
    //     navigate(navigation.newProject)
    //   } else {
    //     navigate(navigation.home)
    //   }
    // } else {
    // }
  }

  // console.log('pathname', window.location.pathname, navigation.home)

  if (!projectId && window.location.pathname.includes(navigation.home)) {
    return null
  }

  return (
    <WppNavSidebar className={styles.sidebar} onWppChange={handleChangeRoute}>
      <WppNavSidebarItem label="My Projects" path={navigation.home} divide>
        <WppIconHome slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem label="New Project" path={navigation.newProject}>
        <WppIconUpload slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem label="Summarise RFI / RFP" path={`${navigation.rfiSummary}/${projectId}`}>
        <WppIconDocument slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem label="Relevant Cases and Pitches" path={`${navigation.relevantNotes}/${projectId}`}>
        <WppIconPitch slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem
        label="Propose Response Structure"
        path={`${navigation.proposedResponseStructure}/${projectId}`}
      >
        <WppIconTaskList slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem label="Answer Specific Questions" path={`${navigation.answerQuestions}`}>
        <WppIconChatMessage slot="icon-start" />
      </WppNavSidebarItem>
      <WppNavSidebarItem label="Ai Draft Response" path={navigation.draftResponse}>
        <WppIconChatMessage slot="icon-start" />
      </WppNavSidebarItem>
      <div className="h-8" />
      <div className="flex flex-row justify-end items-center gap-3 absolute bottom-0 right-3">
        <WppButton variant="secondary">Back</WppButton>
        <WppButton>Next</WppButton>
      </div>
    </WppNavSidebar>
  )
}
